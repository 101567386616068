import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/Home/Hero"
import Features from "../components/Home/Features"
import Testimonials from "../components/Home/Testimonials"
import Events from "../components/Home/Events"
// import Partnerships from "../components/Home/Partnerships"
import Statistics from "../components/Home/Statistics"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Hero />
    <Features />
    <Testimonials />
    <Events />
    <Statistics />
  </Layout>
)

export default IndexPage
