import React from "react"
import { IoIosGitNetwork, IoIosPeople, IoIosMegaphone } from "react-icons/io"
import { orgAcronym, members } from "../../constants"

const features = [
  {
    title: "Networking",
    content: `A safe space for casual networking with our ${members}+ members.`,
    icon: <IoIosGitNetwork />,
  },
  {
    title: "Mentorship",
    content: "Find a mentor or a mentee in diverse industries.",
    icon: <IoIosPeople />,
  },
  {
    title: "Spotlight",
    content:
      "Original content and resources with insightful and helpful information.",
    icon: <IoIosMegaphone />,
  },
]

export default function Features() {
  return (
    <div className="features-container">
      <h4>Why {orgAcronym}</h4>
      <h3>
        Build extraordinary connections<span className="period">.</span>
      </h3>
      <div className="features">
        {features.map((feature, i) => (
          <div className="feature-container" key={feature.title}>
            <div className="icon">{feature.icon}</div>
            <div className="title">{feature.title}</div>
            <div className="content">{feature.content}</div>
          </div>
        ))}
      </div>
    </div>
  )
}
