import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { orgName, members } from "../../constants"
import Link from "gatsby-link"

export default function Hero() {
  const data = useStaticQuery(graphql`
    query {
      hero: file(relativePath: { eq: "hero.png" }) {
        childImageSharp {
          fluid(maxWidth: 1030) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const hero = data.hero.childImageSharp.fluid
  const watermarkArr = ["Ryse", "/rīz/", "Network"]

  return (
    <div className="hero-container">
      <section className="hero">
        <div className="hero-text">
          <div className="hero-title">
            {members}+ professionals looking for their next opportunity
            <span className="period">.</span>
          </div>
          <div className="hero-subtitle">
            <strong>{orgName}</strong> is a diverse community of Asian
            professionals that support, empower, and educate each other.
          </div>
          <div className="button-container">
            <a
              href={"https://www.facebook.com/groups/subtle.asian.networking"}
              rel="noopener"
              target="_blank"
            >
              <button className="main-button">Join Us</button>
            </a>
            <Link to="/about">
              <button className="secondary-button">Learn More</button>
            </Link>
          </div>
        </div>
        <div className="hero-image">
          <Img fluid={hero} alt="hero networking diagram" />
        </div>
      </section>
      <div className="watermark-container">
        {watermarkArr.map((watermark, i) => (
          <div className="watermark" key={i}>
            {watermark}
          </div>
        ))}
      </div>
    </div>
  )
}
