import React from "react"
import { members } from "../../constants"

export default function Statistics() {
  return (
    <div className="stats-container">
      <div className="title">
        <h4>Who we are</h4>
        <h3>
          Be part of our network<span className="period">.</span>
        </h3>
        <a
          href={"https://www.facebook.com/groups/subtle.asian.networking"}
          rel="noopener"
          target="_blank"
        >
          <button className="action-button join">Join Us</button>
        </a>
      </div>
      <div className="stat-container">
        <div className="stat">
          <div className="stat-main ">100+</div>
          <div className="stat-content">cities from around the world.</div>
        </div>
        <div className="stat">
          <div className="stat-main">{members}+</div>
          <div className="stat-content">members in our network.</div>
        </div>
        <div className="stat">
          <div className="stat-main">420+</div>
          <div className="stat-content">companies our members work in.</div>
        </div>
      </div>
    </div>
  )
}
