import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import dayjs from "dayjs"
import _ from "lodash"
import EventsCard from "../shared/EventsCard"
import Link from "gatsby-link"

export default function Events() {
  const data = useStaticQuery(graphql`
    {
      allPrismicEvent(sort: { fields: data___start_datetime, order: ASC }) {
        nodes {
          id
          tags
          data {
            start_datetime
            end_datetime
            excerpt {
              text
            }
            title {
              text
            }
            external_link {
              url
            }
            image {
              alt
              localFile {
                childImageSharp {
                  fluid(maxWidth: 306, maxHeight: 200) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const {
    allPrismicEvent: { nodes: rawEvents },
  } = data

  let events = []

  if (rawEvents != null) {
    events = rawEvents.map(rawEvent => {
      const {
        id,
        tags,
        data: {
          title: { text: title },
          excerpt: { text: excerpt },
          start_datetime: startDateTimeAsString,
          end_datetime: endDateTimeAsString,
          external_link: { url: externalLink },
          image: { alt: imageAltText },
        },
      } = rawEvent

      const image = _.get(
        rawEvent,
        "data.image.localFile.childImageSharp.fluid"
      )

      const startDate = dayjs(startDateTimeAsString)
      const endDate = dayjs(endDateTimeAsString)
      const isEndDateExists =
        endDateTimeAsString != null && endDateTimeAsString !== ""

      if (
        !startDate.isValid() ||
        (isEndDateExists && !endDate.isValid()) ||
        title == null ||
        externalLink == null
      ) {
        return null
      }

      // We check to see if the event is over.
      // If end date exists, we use that to see if end date is before
      // our current date, and if so, the event is over.
      // If end date does not exist, we use the start date.
      if (isEndDateExists && endDate.isBefore(dayjs())) {
        return null
      } else if (!isEndDateExists && startDate.isBefore(dayjs())) {
        // There was no end date, and start date is before our current date.
        return null
      }

      return {
        id,
        tags,
        title,
        excerpt,
        externalLink,
        startDateTimeAsString,
        endDateTimeAsString,
        startDate,
        endDate,
        image,
        imageAltText,
      }
    })

    events = _.compact(events)

    // Get only the first 3 events.
    events = events.slice(0, 3)

    events = events.map(singleEvent => {
      const { startDate, endDate } = singleEvent

      const startFormatted = startDate.format("MMMM D, YYYY")
      const endFormatted = endDate.format("MMMM D, YYYY")
      const startTime = startDate.format("h:mmA")
      const endTime = endDate.format("h:mmA")

      let formattedDateString

      if (!endDate.isValid()) {
        formattedDateString = `${startFormatted} ${startTime}`
      } else if (startFormatted === endFormatted) {
        // They are the same day, show time before -> time after
        formattedDateString = `${startFormatted} ${startTime} - ${endTime}`
      } else {
        // Different days, gotta show both days.
        formattedDateString = `${startFormatted} ${startTime} - ${endFormatted} - ${endTime}`
      }

      return {
        ...singleEvent,
        formattedDateString,
      }
    })
  }

  return (
    <div className="events-container flex flex-col items-start">
      <h4>Stay tuned</h4>
      <h3>
        Upcoming events<span className="period">.</span>
      </h3>
      {events.length === 0 ? (
        <div className="event">No planned events yet. But we'll meet soon!</div>
      ) : (
        <>
          <div className="mt-4 grid grid-cols-1 gap-x-4 gap-y-8 md:grid-cols-3">
            {events.map(event => {
              return <EventsCard key={event.id} event={event} />
            })}
          </div>
          <Link
            to="/events"
            className="main-button mt-8 self-center md:self-start"
          >
            See All Events
          </Link>
        </>
      )}
    </div>
  )
}
