import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import Card from "../shared/Card"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

export default function Testimonials() {
  const data = useStaticQuery(graphql`
    {
      allPrismicBlogPost(
        limit: 3
        sort: { fields: data___release_date, order: DESC }
      ) {
        nodes {
          id
          uid
          tags
          data {
            title {
              text
            }
            release_date(formatString: "MMMM DD YYYY")
            author {
              document {
                ... on PrismicAuthor {
                  id
                  data {
                    name {
                      text
                    }
                  }
                  dataString
                }
              }
            }
            featured_image {
              alt
              localFile {
                childImageSharp {
                  fluid(maxWidth: 306, maxHeight: 200) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const {
    allPrismicBlogPost: { nodes: rawPosts },
  } = data

  let posts = []

  if (rawPosts != null) {
    posts = rawPosts.map(rawPost => {
      const {
        id,
        tags,
        uid: slug,
        data: {
          title: { text: title },
          release_date: createdAt,
          author: {
            document: {
              data: {
                name: { text: author },
              },
            },
          },
          featured_image: {
            alt: featuredImageAltText,
            localFile: {
              childImageSharp: { fluid: featuredImage },
            },
          },
        },
      } = rawPost

      return {
        id,
        tags,
        slug,
        title,
        author,
        createdAt,
        featuredImage,
        featuredImageAltText,
      }
    })
  }

  return (
    <div className="testimonials-container flex flex-col">
      <h4>Latest Posts</h4>
      <h3>
        Hear from our members<span className="period">.</span>
      </h3>
      {posts.length === 0 ? (
        <div>No posts have been written yet. But we will soon!</div>
      ) : (
        <div className="mt-4 grid grid-cols-1 gap-x-4 gap-y-8 md:grid-cols-3">
          {posts.map(post => {
            return (
              <Link key={post.id} to={`/stories/${post.slug}`}>
                <Card
                  title={post.title}
                  top={post.author}
                  subtitle={post.createdAt}
                  image={{
                    fluidImg: post.featuredImage,
                    imgAlt: post.featuredImageAltText,
                  }}
                  tags={post.tags}
                />
              </Link>
            )
          })}
        </div>
      )}
      <Link
        to="/stories"
        className="main-button mt-8 self-center md:self-start"
      >
        See All Stories
      </Link>
    </div>
  )
}
